import "./aboutus.css"
import React, { useEffect } from 'react';
import roky from './Roky.png';
import pingu from './Pingu.png';
import howtolean from './howtolean.png';
import revolver from './revolver.png';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


const markdown = `
# Welcome to Bruddas!

We’re a small, passionate game development studio with big dreams, dedicated to crafting innovative and engaging games that spark imagination. At Bruddas, our focus is on delivering immersive experiences through the power of **Unity**, a platform we’ve come to love for its endless possibilities in game creation.

### Who We Are
Behind Bruddas are two creative forces:

- **Pingu**: Creates game mechanics, always explores fresh ways to make gameplay both fun and challenging.
- **Roky**: Makes models for our great games.

### What We Believe In
We’re more than just developers—we’re gamers ourselves. We understand what makes a game not only playable but unforgettable. Our journey is built on a foundation of creativity, collaboration, and a love for games that we hope to share with every player who experiences our work.

Thank you for visiting **Bruddas**! We’re thrilled to have you here and can’t wait to share the adventures we’re creating.

Join US!!!
  `;

function Aboutus() {
    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.card, .subcard');
            elements.forEach(element => {
                const rect = element.getBoundingClientRect();
                if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                    element.classList.add('visible');
                } else {
                    element.classList.remove('visible');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        // Run the scroll handler once to check the initial position
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>

            <div className="card">
                <p className="about">About Us</p>
                <hr class="horizontal-line"></hr>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
                <p></p>
                <hr class="horizontal-line"></hr>
            </div>

            <div className="card">
                <p className="about">Our Team</p>
                <hr class="horizontal-line"></hr>
                <div className="pfps">
                    <div className="subcard">
                        <a href="https://discord.com/invite/86W8cDwTAy"><img className="pfp" src={roky} alt="" /></a>
                        
                        <p>Roky</p>
                        <p className="smalltext">3d artist     
                        
                    </p>
                    
                    </div>

                    <div className="subcard">
                    <a href="https://discord.com/invite/86W8cDwTAy"><img className="pfp" src={pingu} alt="" /></a>
                    <p>Pingu</p>
                    <p className="smalltext">Game dev
                    Programmer
                    </p>
                    </div>
                   
                </div>

                <hr class="horizontal-line"></hr>
            </div>

            <div className="card">
                <p className="about">Our Games</p>
                <hr class="horizontal-line"></hr>
                <div className="pfps">
                    <div className="subcard">
                    <a href="https://bruddasgamestudio.itch.io/howtomakelean"><img className="pfp" src={howtolean} alt="" /></a>
                        <p>How to lean</p>
                        <p className="smalltext">Contributors:
                            Pingu,Roky   
                        
                    </p>
                    
                    </div>

                    <div className="subcard">
                    <a href="https://bruddasgamestudio.itch.io/revolver-showdown"><img className="pfp" src={revolver} alt="" /></a>
                    
                    <p>Revolver showdown</p>
                    <p className="smalltext">Contributors:
                        Pingu,Roky   
                    </p>
                    </div>
                   
                </div>
                <hr class="horizontal-line"></hr>
            </div>
        </>

    );
}

export default Aboutus;
