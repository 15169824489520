import "./bruddas.css"

function Bruddas() {
    return (
        <div class="center">
        <h1>
          <span>Bruddas</span>
        
          <span>Bruddas</span>
          <span>Bruddas</span>
        
        </h1>
      </div>
    );
  }
  
  export default Bruddas;
  