import "./index.css"
import Bruddas from "./components/bruddas";
import Aboutus from "./components/aboutus";
function Index() {
    return (
       <>
    
       <Bruddas></Bruddas>
       <Aboutus></Aboutus>
       </>
    );
  }
  
  export default Index;
  